// Neosoma color #109abc

.base-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
  }

  #speceficeImage {
    height: 25%;
  }

  .content {
    display: flex;
    flex-direction: column;

    .image {
      width: 21em;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .form {
      margin-top: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: fit-content;
        label {
          font-weight: bold;
          font-style: italic;
          font-size: 12px;
        }
        input {
          margin-top: 6px;
          min-width: 18em;
          height: 32px;
          padding: 0px 10px;
          font-size: 15px;
          font-family: "Open Sans", sans-serif;
          background-color: #f0f3f8;
          border: 0;
          border-radius: 10px;
          margin-bottom: 5px;
          transition: all 250ms ease-in-out;
          &:hover {
            background-color: #ffffff;
            box-shadow: 0px 0px 14px 0.3px #0e81ce96;
          }

          &:focus {
            outline: none;
            box-shadow: 0px 0px 12px 0.8px #3474dbb2;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    margin-top: 0.5em;
  }
}

.ResetPW {
  color: #109abc;
  text-align: left;
  margin-bottom: 6px;
  background-color: transparent;
  border: 0;
  border-color: black;
}
.ResetPW:hover {
  text-decoration: underline;
  font-weight: bolder;
}
.ResetPW:focus {
  outline: none;
}

#FirstID {
  min-width: 0em;
  width: 130px;
  margin-right: 10px;
}
#lastID {
  min-width: 0em;
  width: 130px;
}

.DropdownDiv {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 270px;
  margin-bottom: 20px;
  &:hover {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0.3px #0e81ce96;
  }
}
#test {
  color: black;
  padding: 5px;
  border-radius: 10px;
  background-color: #f0f3f8;
}
.DropdownText {
  text-align: left;
  font-weight: bold;
  font-style: italic;
  width: 270px;
  font-size: 12px;
}

.radioTxt{
  // color: #109abc; 
  font-size:xx-small;
  margin-bottom: 20px;
}
.ant-radio-button-wrapper-checked { 
  background-color: #109abc !important;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    transform: scale(0);
  }
}

.fading {
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  will-change: opacity;
  overflow: hidden;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadingin {
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  will-change: opacity;
}
