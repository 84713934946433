// Neosoma color #109abc

.rangeslider-horizontal .rangeslider__fill {
    background-color: #1e88e5;
}
.rangeslider-horizontal .rangeslider__handle:after {
    content: none;
}
.rangeslider-vertical .rangeslider__fill {
    background-color: #1e88e5;
}
.rangeslider-vertical .rangeslider__handle {
    border-radius: 50%;
    height: 30px;
}
.cardTitle {
    text-align: center;
}

.skletetonStyle {
    width: 250px;
    height: 250px;
    border-radius: 20px;
    margin-right: 20px;
    margin-left: 20px;
}
.mriStyle {
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 20px;
    transform: scale(1.1);
}
.containerStyle {
    transition: transform 1000ms;
    background-color: #f1f6f9;
    padding: 10px;
    margin-right: 20px;
    border-radius: 10px;
    margin-top: 10px;
    position: relative;
    transition: transform 1s cubic-bezier(0.07, 0.4, 0.66, 2);
    &:hover {
        box-shadow: 0px 0px 12px 0.8px #109abc;
    }
}

.TermsStyle {
    display: flex;
    flex-direction: column;
    // align-items:center;
    width: 50%;
    margin: 20px;
    border-radius: 10px;
    padding: 15px 40px;
    background-color: #f1f6f9;
    box-shadow: 0px 0px 2px 0.3px #394867;
}
.FocusMode {
    background-color: #f1f6f9;
    padding: 10px;
    border-radius: 10px;
    position: fixed;
    z-index: 1;
    transition: transform 1s cubic-bezier(0.07, 0.4, 0.66, 2.5);
    transform: scale(1.4);
}

.TopBtnsStyle {
    position: absolute;
    right: 0px;
    display: flex;
}

.TopIcon {
    &:hover {
        border-radius: 5px;
        color: #109abc;
    }
}
.TopBtn {
    margin-right: 15px;
}
.secondStyle {
    display: flex;
    flex-direction: row;
}
.overLayedImg {
    position: absolute;
    margin-left: 20px;
    margin-right: 10px;
    transform: scale(1.1);
}

.menuStyle {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
}
#buttonMenu {
    font-size: 12px;
    min-width: 10em;
    margin-right: 10px;
    background-color: #109abc;
}

#submitBtn {
    margin-left: 20px;
    font-size: 15px;
    min-width: 8em;
    background-color: #109abc;
    box-shadow: 2px 3px 2px 2px #394867;
}
#submitBTnselected {
    margin-left: 20px;
    font-size: 15px;
    min-width: 8em;
    box-shadow: inset 0 50px 0 0 #142850;
    min-width: 9em;
}
#submitBtn:hover {
    box-shadow: 0px 0px 14px 0.3px #0e81ce96;
    // min-width: 9em;
}

// #SelectedBTNHighlighted {
//     background-color: #109abc;
//     min-width: 8em;
//     font-size: 14px;
//     margin-bottom: 10px;
//     margin-top: 15px;
//     box-shadow: 2px 3px 2px 2px #394867;
// }
// #SelectedBTNHighlighted:hover {
//     box-shadow: 0px 0px 14px 0.3px #0e81ce96;
//     min-width: 10em;
// }

// #SelectedBTN {
//     margin-top: 10px;
//     margin-bottom: 10px;
//     font-size: 18px;
//     min-width: 10em;
//     background-color: #142850;
// }
// .divBTNStyle {
//     display: flex;
//     justify-content: center;
// }
.botSliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SliderClass {
    margin: 0px;
    width: 200px;
}
.SliderClass2 {
    margin: 0px;
    margin-right: 10px;
    margin-top: 5px;
    width: 110px;
}
.VSliderClass {
    height: 100px;
    margin-right: 10px;
}
.SlideandLabel {
    transform: translateY(20%);
    display: flex;
    flex-direction: row;
}

.CommentStyle {
    width: 300px;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    background-color: #f0f3f8;
    border: 0;
    border-radius: 10px;
    transition: all 250ms ease-in-out;
    &:hover {
        background-color: #ffffff;
        box-shadow: 0px 0px 14px 0.3px white;
    }

    &:focus {
        animation: topBubbles ease-in-out 0.75s forwards;
        width: 500px;
        outline: none;
        box-shadow: 0px 0px 12px 0.8px #109abc;
    }
}

.HeaderStyle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.HeaderStyle > div {
    margin-left: 20px;
}

.topTextstyle {
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

.PageStyle {
    background-color: #dae1e7;
}
.disableDiv {
    margin-top: 50px;
    pointer-events: none;
}
.UndecidedDiv {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.BotDiv {
    margin-top: 15px;
    padding-bottom: 30px;
}

.sliderText {
    font-size: 8px;
    margin-bottom: 10px;
    margin-top: 15px;
    font-style: italic;
    font-weight: bold;
}

.SegmentationText {
    transform: rotate(90deg);
    width: 0px;
    height: 10px;
    font-size: 8px;
    margin-bottom: 10px;
    margin-top: 15px;
    font-style: italic;
    font-weight: bold;
}
.ProgressBar {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.ant-divider-horizontal {
    margin: 5px;
}
.ant-progress-bg {
    background-color: #109abc;
}
.ant-slider-rail {
    background-color: white;
}
.ant-slider-track {
    background-color: #109abc;
}
.ant-modal-content {
    border-radius: 20px;
    box-shadow: 0px 0px 12px 0.8px #109abc;
    background-color: #dae1e7;
}
.ant-btn.ant-btn-primary {
    background-color: #109abc;
    width: 80px;
    border-radius: 10px;
    &:hover {
        width: 90px;
        box-shadow: 0px 0px 14px 0.3px #0e81ce96;
    }
}
.ant-btn {
    width: 80px;
    border-radius: 10px;
    &:hover {
        width: 90px;
        box-shadow: 0px 0px 14px 0.3px #0e81ce96;
    }
}
.DialogLabel {
    font-style: italic;
    font-weight: bold;
}
.TopHeadeBTNs {
    margin-right: 10px;
    font-size: calc(0.1em + 0.7vw);
    min-width: 0px;
    color: #109abc;
    background-color: #dae1e7;
    margin-top: 5%;
    &:hover {
        box-shadow: 0px 0px 14px 0.3px #0e81ce96;
        background-color: #109abc;
        color: #dae1e7;
    }
}
.HeaderTitle {
    color: #109abc;
    padding-left: 10px;
    font-size: calc(1em + 1.5vw);
}
.ant-drawer-body {
    background-color: #142850;
}
.MenuItem {
    color: #109abc;
    font-size: 30px;
    margin-top: 15px;
    margin-right: 10px;
    transition: 0.5s;
    &:hover {
        transition: 0.5s;
        color: white;
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }
}
.BottomDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.HandImage {
    width: 20px;
    animation: left-to-right 1s linear infinite alternate;
}
@keyframes left-to-right {
    0% {
        transform: translateX(-5px) rotate(90deg);
    }
    50% {
        transform: translateX(5px) rotate(90deg);
    }
    100% {
        transform: translateX(0px) rotate(90deg);
    }
}

.HandImage2 {
    width: 20px;
    animation: top-to-bot 1s linear infinite alternate;
}
@keyframes top-to-bot {
    0% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0px);
    }
}
.HideDiv {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}
.showDiv {
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
}
