.tabs {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20px;
}

.tab-list {
    display: flex;
    padding-bottom: 25px;
    justify-content: center;
}

.tab {
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 10px;
    color: #109abc;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    margin-right: 24px;
    transition: color 0.16s ease-in-out, background-color 0.16s ease-in-out, border-color 0.16s ease-in-out;
}

.tab.active {
    background-color: #109abc;
    border-color: #109abc;
    color: white;
    cursor: default;
}

.panel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.Piechart {
    background-color: #f1f6f9;
    border-radius: 10px;
}
.LabelStyle {
    display: flex;
    font-size: 20px;
    margin-bottom: 10px;
    justify-content: center;
}

.PanelHolder {
    display: flex;
    justify-content: center;
}

.TableClass {
    margin: 0px 50px;
}
.table {
    background-color: #109abc;
    text-align: center;
    &:hover {
        box-shadow: 0px 0px 12px 0.8px #394867;
    }
}
.anticon {
    vertical-align: 0px;
}
